<script lang="ts">
import { defineComponent } from 'vue'
import { ApDevice } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'DeviceList',
	props: {
		devices: {
			type: Array,
			required: true,
		},
	},
	emits: ['device-selected'],
	methods: {
		formatDate (row: ApDevice, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd / HH:mm')
		},
		rowClick (row: ApDevice): void {
			this.$emit('device-selected', row.id)
		},
	},
})
</script>

<template>
	<el-card class="box-card">
		<el-table :data="devices" style="width: 100%" @row-click="rowClick">
			<el-table-column prop="id" label="ID Appareil" width="180"></el-table-column>
			<el-table-column prop="description" label="Description" width="300"></el-table-column>
			<el-table-column
				prop="first_seen_at"
				:formatter="formatDate"
				label="Première connexion"
				width="220"
			></el-table-column>
			<el-table-column
				prop="last_seen_at"
				:formatter="formatDate"
				label="Dernière connexion"
				width="220"
			></el-table-column>
			<el-table-column prop="customer_id" label="Client" width="320"></el-table-column>
		</el-table>
	</el-card>
</template>
