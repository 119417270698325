
import { defineComponent } from 'vue'
import { ApCustomer } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'CustomerList',
	props: {
		customers: {
			type: Array,
			required: true,
		},
	},
	emits: ['customer-selected'],
	methods: {
		formatDate (row: ApCustomer, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd')
		},
		rowClick (row: ApCustomer): void {
			this.$emit('customer-selected', row.id)
		},
	},
})
