
import { defineComponent } from 'vue'
import CustomerForm from '@/components/CustomerForm.vue'
import { ApCustomerAdd } from '@/types'

export default defineComponent({
	name: 'CustomerEdit',
	components: {
		CustomerForm,
	},
	props: {
		id: {
			type: String,
			default: '',
		},
	},
	data () {
		return {
			loaded: false,
			errored: false,
			loading: false,
			customer: {
				company_name: '',
				contact_firstname: '',
				contact_lastname: '',
				email: '',
				password: '',
				subscription_offer: 'none',
				subscription_expire_at: '',
			},
		}
	},
	mounted () {
		fetch(`${process.env.VUE_APP_API_URL}/customer/${this.id}`)
			.then(async (response: any) => {
				const data = await response.json()
				this.customer = {
					company_name: data.company_name,
					contact_firstname: data.contact_firstname,
					contact_lastname: data.contact_lastname,
					email: data.email,
					password: '',
					subscription_offer: data.subscription_offer,
					subscription_expire_at: data.subscription_expire_at,
				}
				this.loaded = true
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		save (customerData: ApCustomerAdd) {
			fetch(`${process.env.VUE_APP_API_URL}/customer/${this.id}/edit`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(customerData),
			})
				.then(async (response: any) => {
					const customer_data = await response.json()
					this.$router.push({ name: 'customer', params: { id: customer_data.id } })
				})
				.catch(() => {
					this.errored = true
				})
				.finally(() => {
					this.loading = false
				})
		},
		cancel (customerId: string): void {
			this.$router.push({ name: 'customer', params: { id: customerId } })
		},
	},
})
