<script lang="ts">
import { defineComponent } from 'vue'
import CustomerCard from '@/components/CustomerCard.vue'
import DeviceList from '@/components/DeviceList.vue'

export default defineComponent({
	name: 'ApCustomer',
	components: {
		CustomerCard,
		DeviceList,
	},
	props: {
		id: {
			type: String,
			required: true,
		},
	},
	data () {
		return {
			errored: false,
			loading: false,
			customer: {
				id: '',
				created_at: '',
				company_name: '',
				contact_firstname: '',
				contact_lastname: '',
				email: '',
				password_hash: '',
				subscription_offer: '',
				subscription_expire_at: '',
			},
			devices: [],
			device_id: '',
		}
	},
	mounted () {
		fetch(`${process.env.VUE_APP_API_URL}/customer/${this.id}`)
			.then(async (response: any) => {
				const data = await response.json()
				this.customer = data
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
		fetch(`${process.env.VUE_APP_API_URL}/customer/${this.id}/devices`)
			.then(async (response: any) => {
				const data = await response.json()
				this.devices = data
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		addDeviceToCustomer (): void {
			fetch(`${process.env.VUE_APP_API_URL}/customer/${this.id}/device_add`, {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ id: this.device_id }),
			})
				.then(async (response: any) => {
					await response.json()
					location.reload()
				})
				.catch(() => {
					this.errored = true
				})
				.finally(() => {
					this.loading = false
				})
		},
	},
})
</script>

<template>
	<customer-card :customer="customer"> </customer-card>
	<device-list :devices="devices"> </device-list>
	<el-card class="box-card">
		<el-input v-model="device_id"></el-input>
		<el-button type="primary" @click="addDeviceToCustomer()">Ajouter Appareil</el-button>
	</el-card>
</template>

<style></style>
