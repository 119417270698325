<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CustomerCard',
	components: {},
	props: {
		customer: {
			type: Object,
			required: true,
		},
	},
	methods: {},
})
</script>

<template>
	<el-card class="box-card">
		<template #header>
			<div class="clearfix">
				<el-button
					style="float: right; padding: 3px 0"
					type="text"
					@click="$router.push({ name: 'customerEdit', params: { id: customer.id } })"
				>
					Modifier
				</el-button>
				<h2>Informations</h2>
			</div>
		</template>
		<el-col class="customer-meta-labels">
			<el-row> Entreprise: </el-row>
			<el-row> Prénom du contact: </el-row>
			<el-row> Nom du contact: </el-row>
			<el-row> Email: </el-row>
			<el-row> Offre: </el-row>
			<el-row> Expiration de l'offre: </el-row>
		</el-col>
		<el-col class="customer-meta-values">
			<el-row> {{ customer.company_name }}</el-row>
			<el-row> {{ customer.contact_firstname }}</el-row>
			<el-row> {{ customer.contact_lastname }}</el-row>
			<el-row> {{ customer.email }}</el-row>
			<el-row> {{ customer.subscription_offer }}</el-row>
			<el-row> {{ customer.subscription_expire_at }}</el-row>
		</el-col>
	</el-card>
</template>

<style>
.customer-meta-labels,
.customer-meta-values {
	margin: 20px;
}
.customer-meta-labels .el-row,
.customer-meta-values .el-row {
	margin: 5px;
}
.customer-meta-values {
	padding-right: 50px;
}
</style>
