
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CustomerCard',
	components: {},
	props: {
		customer: {
			type: Object,
			required: true,
		},
	},
	methods: {},
})
