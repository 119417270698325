
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CustomerForm',
	props: {
		customer: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localCustomer: JSON.parse(JSON.stringify(this.customer)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localCustomer)
		},
		onCancel (): void {
			this.$emit('cancel', this.localCustomer.id)
		},
	},
})
