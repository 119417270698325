
import { defineComponent } from 'vue'
import CustomerList from '@/components/CustomerList.vue'

export default defineComponent({
	name: 'ApCustomers',
	components: {
		CustomerList,
	},
	data () {
		return {
			errored: false,
			loading: false,
			customers: [],
		}
	},
	mounted () {
		fetch(`${process.env.VUE_APP_API_URL}/customers`)
			.then(async (response: any) => {
				this.customers = await response.json()
			})
			.catch(() => {
				this.errored = true
			})
			.finally(() => {
				this.loading = false
			})
	},
	methods: {
		selectCustomer (id: string): void {
			this.$router.push({ name: 'customer', params: { id } })
		},
	},
})
