<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
	name: 'CustomerForm',
	props: {
		customer: {
			type: Object,
			required: true,
		},
		isNew: {
			type: Boolean,
			default: false,
		},
	},
	emits: ['submit', 'cancel'],
	data () {
		return {
			localCustomer: JSON.parse(JSON.stringify(this.customer)),
		}
	},
	methods: {
		onSubmit (): void {
			this.$emit('submit', this.localCustomer)
		},
		onCancel (): void {
			this.$emit('cancel', this.localCustomer.id)
		},
	},
})
</script>

<template>
	<el-form ref="form" :model="customer" label-width="250px">
		<el-form-item label="Nom de l'entreprise">
			<el-input v-model="localCustomer.company_name"></el-input>
		</el-form-item>
		<el-form-item label="Prénom du contact">
			<el-input v-model="localCustomer.contact_firstname"></el-input>
		</el-form-item>
		<el-form-item label="Nom du contact">
			<el-input v-model="localCustomer.contact_lastname"></el-input>
		</el-form-item>
		<el-form-item label="Niveau d'offre">
			<el-select v-model="localCustomer.subscription_offer" placeholder="Niveau de l'offre">
				<el-option selected label="Aucune" value="none"></el-option>
				<el-option label="Estimation / Cubage" value="estimation-cubage"></el-option>
				<!-- el-option disabled label="ApTECH Chantier" value="aptech-chantier"></el-option>
				<el-option disabled label="ApTECH Usine" value="aptech-usine"></el-option>
				<el-option disabled label="Transport" value="transport"></el-option-->
			</el-select>
		</el-form-item>
		<el-form-item label="Date de fin d'abonnement">
			<el-date-picker v-model="localCustomer.subscription_expire_at" type="date" placeholder="Choisir un jour">
			</el-date-picker>
		</el-form-item>
		<el-form-item label="Email">
			<el-input v-model="localCustomer.email"></el-input>
		</el-form-item>
		<el-form-item label="Mot de passe">
			<el-input v-model="localCustomer.password"></el-input>
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">Enregistrer</el-button>
			<el-button @click="onCancel">Annuler</el-button>
		</el-form-item>
	</el-form>
</template>
