
import { defineComponent } from 'vue'
import { ApDevice } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'DeviceList',
	props: {
		devices: {
			type: Array,
			required: true,
		},
	},
	emits: ['device-selected'],
	methods: {
		formatDate (row: ApDevice, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd / HH:mm')
		},
		rowClick (row: ApDevice): void {
			this.$emit('device-selected', row.id)
		},
	},
})
