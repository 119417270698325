<script lang="ts">
import { defineComponent } from 'vue'
import { ApCustomer } from '@/types'
import { format, parseISO } from 'date-fns'

export default defineComponent({
	name: 'CustomerList',
	props: {
		customers: {
			type: Array,
			required: true,
		},
	},
	emits: ['customer-selected'],
	methods: {
		formatDate (row: ApCustomer, col: any, value: any) {
			return format(parseISO(value), 'yyyy-MM-dd')
		},
		rowClick (row: ApCustomer): void {
			this.$emit('customer-selected', row.id)
		},
	},
})
</script>

<template>
	<el-table :data="customers" style="width: 100%" @row-click="rowClick">
		<el-table-column prop="company_name" label="Entreprise" width="180"></el-table-column>
		<el-table-column prop="contact_firstname" label="Prénom" width="150"></el-table-column>
		<el-table-column prop="contact_lastname" label="Nom" width="150"></el-table-column>
		<el-table-column prop="email" label="Email" width="300"></el-table-column>
		<el-table-column prop="subscription_offer" label="Offre d'abonnement" width="180"></el-table-column>
		<el-table-column
			prop="subscription_expire_at"
			:formatter="formatDate"
			label="Expiration"
			width="180"
		></el-table-column>
	</el-table>
</template>
